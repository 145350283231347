<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item>出票管理 </el-breadcrumb-item>
			<el-breadcrumb-item>出票登记申请</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<el-button type="primary" @click="goDraft">草稿箱</el-button>
			<!--步骤条-->
			<el-steps :active="activeStep"
			          process-status="wait"
			          finish-status="finish"
			          align-center
								class="mt30">
				<el-step title="填写票据信息"></el-step>
				<el-step title="上传贸易背景"></el-step>
				<el-step title="核对信息"></el-step>
				<el-step title="完成"></el-step>
			</el-steps>
			<!--填写票据信息-->
			<BillOutOne v-if="activeStep === 1" @handleSave="handleSave" :id="id"/>
			<!--上传贸易背景-->
			<BillOutTwo v-else-if="activeStep === 2" @handleSave="handleSave" :id="id" :tradeAmt="tradeAmt"/>
			<!--核对信息-->
			<BillOutThree v-else-if="activeStep ===3" @handleSave="handleSave" :id="id"/>
			<!--完成-->
			<Status v-else>
				<template v-slot:statusDescribe>
					<h3>出票登记申请成功</h3>
					<h3 class="operation-number">操作编号：<span>{{number}}</span></h3>
					<el-button type="primary" @click="handleCopy" style="margin-top: 20px">点击复制</el-button>
				</template>
				<template v-slot:statusBtn>
					<el-button type="primary" size="medium" @click="submitAccess">完成</el-button>
				</template>
			</Status>
		</el-card>
	</div>
</template>

<script>
	import BillOutOne from "./childCpn/BillOutOne";
	import BillOutTwo from "./childCpn/BillOutTwo";
	import BillOutThree from "./childCpn/BillOutThree";
	import Status from "@/views/Ticket/childrenCpn/Status";
  export default {
    name: "BillOutApply",
	  components: {
      BillOutOne,
      BillOutTwo,
      BillOutThree,
      Status
	  },
	  mounted(){
      console.log(this.$route)
      this.id = this.$route.query.id || ''
	  },
	  data(){
      return {
        activeStep: 1,// 步骤条当前激活步骤
	      id: '', // 票据信息保存时返回的id
	      number: '', //操作成功后展示的操作编号
        tradeAmt: '', //第一步填写的票据包金额
      }
	  },
	  methods: {
      // 访问草稿箱
      goDraft(){
        this.$router.push({
	        name: 'billOutDraft'
        })
      },
		  // 监听第一步 - 填写票据信息
      handleSave({activeStep, id, data, tradeAmt}){
        console.log('number', data)
        this.activeStep = activeStep;
	      this.id = id;
	      this.number = data;
	      this.tradeAmt = tradeAmt
      },
		  // 监听完成按钮点击返回第一步
      submitAccess(){
        this.activeStep = 1;
        this.id = ''
      },
      // 点击复制
      handleCopy(){
        this.$copyText(this.number).then(res => {
          this.$message.success('复制成功')
          this.successDialogVisible = false
          return false;
        },err => {
          this.$message.error('复制失败')
          this.successDialogVisible = true
        })
      },
	  }
  }
</script>

<style lang="less" scoped>
	h3{
		text-align: center;
	}
	.btn{
		text-align: center;
		margin: 50px auto 20px;
	}
	.operation-number{
		margin-top: 10px;
		span{
			color: #C70009;
		}
	}
</style>