<template>
	<div>
		 <!-- 票面信息 - 一期后加的 -->
    <BillFaceInfo 
      :maker="info.maker" 
      :acceptor="info.acceptor" 
      :taker="info.taker" 
      :ticketInfo="info.ticketInfo"
			:ticketBaseInfoList="null">
    </BillFaceInfo>
		<!--其他信息-->
		<el-card>
			<el-descriptions title="其他信息" :column="3">
				<el-descriptions-item label="自动提示承兑">
					<span v-if="info.otherInfo.acceptance == '1'">是</span>
					<span v-else>否</span>
				</el-descriptions-item>
				<el-descriptions-item label="自动提示收票">
					<span v-if="info.otherInfo.collection == '1'">是</span>
					<span v-else>否</span>
				</el-descriptions-item>
				<el-descriptions-item label="备注">{{isRemark}}</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<!--发票信息-->
		<el-card>
			<h3>发票信息</h3>
			<el-table :data="info.invoiceInfo.invoiceList" border stripe class="mt20">
				<el-table-column label="序号" type="index" width="50px"></el-table-column>
				<el-table-column label="发票代码" prop="invoiceCode" min-width="120px"></el-table-column>
				<el-table-column label="发票号码" prop="invoiceNum" min-width="100px"></el-table-column>
				<el-table-column label="开票日期" prop="invoiceDate" min-width="100px"></el-table-column>
				<el-table-column label="价税合计（元）" prop="invoiceTotal" min-width="120px">
					<template v-slot="scope">
						<span>{{parseFloat(scope.row.invoiceTotal).toFixed(2)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="不含税金额（元）" prop="invoiceAmt" min-width="140px">
					<template v-slot="scope">
						<span>{{parseFloat(scope.row.invoiceAmt).toFixed(2)}}</span>
					</template>
				</el-table-column>
				<el-table-column label="购买方" prop="payName" min-width="120px"></el-table-column>
				<el-table-column label="销售方" prop="sellerName" min-width="120px"></el-table-column>
				<el-table-column label="后六位校验码" prop="checkCode" min-width="120px"></el-table-column>
				<el-table-column label="操作" fixed="right" min-width="100px">
					<template v-slot="scope">
						<el-button type="primary" size="mini" @click="lookInvoice(scope.row)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="bill-all">
				<span>价税合计（总计）：{{parseFloat(info.invoiceInfo.taxTotal).toFixed(2)}}元</span>
				<span>发票张数：{{info.invoiceInfo.invoiceTotal}}张</span>
			</div>
		</el-card>
		<!--合同信息-->
		<el-card>
			<el-descriptions title="合同信息" :column="2">
				<el-descriptions-item label="合同编号">{{info.contractInfo.no}}</el-descriptions-item>
				<el-descriptions-item label="合同日期">{{info.contractInfo.date}}</el-descriptions-item>
				<el-descriptions-item label="合同金额（元）">{{info.contractInfo.money}}</el-descriptions-item>
				<el-descriptions-item label="合同信息分类">
					<span v-if="info.contractInfo.contractClass == 1">合同</span>
					<span v-else>订单</span>
				</el-descriptions-item>
				<el-descriptions-item label="贸易背景方式">
					<span v-if="info.contractInfo.way == '1'">货物贸易</span>
					<span v-else-if="info.contractInfo.way == '2'">服务贸易</span>
					<span v-else-if="info.contractInfo.way == '3'">货服贸易</span>
				</el-descriptions-item>
				<el-descriptions-item label="合同附件">
					<a :href="info.fileUrl" target="_blank" class="accessory">{{info.contractInfo.accessoryName}}</a>
				</el-descriptions-item>
			</el-descriptions>
		</el-card>
		<div class="btn">
			<el-button @click="handleBack">上一步</el-button>
			<el-button type="primary" @click="handleSubmit">保存并继续</el-button>
		</div>
		<!--查看发票对话框-->
		<el-dialog :visible.sync="invoiceDialogVisible">
			<img :src="invoiceUrl" class="invoiceImg" alt="">
			<span slot="footer" class="dialog-footer">
		    <el-button @click="invoiceDialogVisible = false">关 闭</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
// 票面信息 - 一期后加的
  import BillFaceInfo from '@/views/Ticket/routerCpn/workbench/publicCpn/BillFaceInfo.vue'
  export default {
    name: "BillOutThree",
    props: {
      id: {
        type: Number,
        default: 0
      }
    },
		components: {
			BillFaceInfo
		},
	  mounted(){
      // 获取核对信息
      this.getCompareInfo()
	  },
    data(){
      return {
        info: {
					ticketInfo: {}, // 票据信息
					maker: {}, // 出票人
					acceptor: {}, //承兑人
					taker: {}, //收票人	
				}, // 查询到的数据
        invoiceDialogVisible: false, // 控制查看发票对话框的显示与隐藏,
        invoiceUrl:'', // 查看发票图片地址
      }
    },
	  computed: {
      // 保留两位小数
      numFilter(value){
        const realValue = parseFloat(value).toFixed(2)
        return realValue
      },
			// 其他信息 - 备注
			isRemark(){
				if(this.info.otherInfo.remark){
					return this.info.otherInfo.remark
				}else{
					return '无'
				}
			}
	  },
    methods: {
      // 获取核对信息
	    async getCompareInfo(){
	      const {data:{data:res}} = await this.$auth.post("/cpiaoju-ticket/ticket/informationShow", this.$qs.stringify({id: this.id}));
        this.info = res
	    },
	    // 返回上一步
      handleBack(){
        this.$emit('handleSave', {activeStep: 2, id:this.id})
      },
	    // 保存
      async handleSubmit(){
	      const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/ticketSubmit", this.$qs.stringify({id: this.id}));
        console.log(res)
	      if(res.code !== 200) return this.$message.error(res.msg);
        this.$emit('handleSave', {activeStep: 4, data: res.data})
      },
      // 查看发票
      lookInvoice(row){
        console.log(row)
        this.invoiceUrl = row.invoiceUrl
        this.invoiceDialogVisible = true
      }
    }
  }
	
</script>

<style lang="less" scoped>
	.el-descriptions{
		/deep/ .el-descriptions__header{
			margin-bottom: 20px;
		}
	}
	.task-face-value{
		width: 100%;
		height: 300px;
		margin-top: 20px;
		border: 1px solid #DEDEDE;
		box-sizing: border-box;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.bill-all{
		padding: 15px 20px;
		border: 1px solid #EBEEF5;
		border-top: none;
		span{
			display: inline-block;
			font-size: 14px;
			color: #666;
			margin-top: 10px;
		}
		span:first-child{
			margin-right: 40px;
		}
	}
	
	.invoiceImg{
		width: 100%;
		height: 100%;
	}
	.btn{
		text-align: center;
		margin: 50px auto 20px;
	}
	.accessory{
		color: #C70009;
	}
</style>