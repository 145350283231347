<template>
	<div>
		<!--<h3 class="mt30">票据信息</h3>-->
		<el-form :model="billInfoForm"
		         :rules="billInfoRules"
		         ref="billInfoRef"
		         label-width="140px">
			<el-form-item style="width: 100%;">
				<h3 class="mt30">出票人</h3>
			</el-form-item>
			<el-form-item label="出票人" prop="drawer">
				{{billInfoForm.drawer}}
			</el-form-item>
			<el-form-item label="出票人账号" prop="acctNo">
				<el-select v-model="billInfoForm.acctNo"
				           placeholder="请选择出票人账号"
									 @change="handleDrawerChange">
					<el-option v-for="(item,index) in accountDrawer"
					           :label="item"
					           :value="item"
										 :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="开户行"
			              prop="drawerDepositBank"
			              style="margin-bottom: 5px!important;">
				{{billInfoForm.drawerDepositBank}}
			</el-form-item>
			<el-form-item label="开户行行号" prop="drawerOpenBank">
				{{billInfoForm.drawerOpenBank}}
			</el-form-item>
			<el-form-item style="width: 100%; margin-bottom: 0">
				<h3 class="mt30" style="float:left;">承兑人</h3>
        <el-button type="primary"
                   v-if="billInfoForm.acctNo !== null"
                   style="float:left; margin-top: 30px; margin-left: -80px;"
                   @click="handleDrawerSame">
          与出票人一致
        </el-button>
			</el-form-item>
			<!--票据类别为商承时-->
			<el-form-item label="承兑人" prop="acceptor">
				<el-input v-model.trim="billInfoForm.acceptor" @blur="handleAcceptorBlur"></el-input>
			</el-form-item>
			<div v-if="billInfoForm.billType == 'AC02' || !billInfoForm.billType">
				<el-form-item label="承兑人账号" prop="acceptorAcctNo">
					<el-select v-model="billInfoForm.acceptorAcctNo"
					           placeholder="请选择承兑人账号"
					           @change="handleAcceptorChange">
						<el-option v-for="(item,index) in accountAcceptor"
						           :label="item"
						           :value="item"
						           :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="开户行"
				              prop="drawerDepositBank"
				              style="margin-bottom: 5px!important;">
					<span>{{billInfoForm.acceptorDepositBank}}</span>
				</el-form-item>
				<el-form-item label="开户行行号" prop="drawerOpenBank">
					<span>{{billInfoForm.acceptorOpenBank}}</span>
				</el-form-item>
			</div>
			<!--票据类别为银承时-->
			<div v-else-if="billInfoForm.billType == 'AC01'" class="ac01">
				<el-form-item label="开户行行号">
					<span>{{billInfoForm.acceptorOpenBankSilver}}</span>
				</el-form-item>
			</div>
			<el-form-item style="width: 100%; margin-bottom: 0">
				<h3 class="mt30">收票人</h3>
			</el-form-item>
			<el-form-item label="收票人" prop="payee">
				<el-input v-model.trim="billInfoForm.payee" @blur="handlePayeeBlur"></el-input>
			</el-form-item>
			<el-form-item label="收票人账号" prop="payeeAcctNo">
				<el-select v-model="billInfoForm.payeeAcctNo"
				           placeholder="请选择收票人账号"
				           @change="handlePayeeChange">
					<el-option v-for="(item,index) in accountPayee"
					           :label="item"
					           :value="item"
					           :key="index"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="开户行"
			              prop="drawerDepositBank"
			              style="margin-bottom: 5px!important;">
				<span>{{billInfoForm.payeeDepositBank}}</span>
			</el-form-item>
			<el-form-item label="开户行行号" prop="drawerOpenBank">
				<span>{{billInfoForm.payeeOpenBank}}</span>
			</el-form-item>
      <el-form-item style="width: 100%">
        <h3 class="mt30">票据信息</h3>
      </el-form-item>
      <el-form-item label="票据类别" prop="billType">
        <el-select v-model="billInfoForm.billType" placeholder="请选择票据类别">
          <el-option label="银票" value="AC01"></el-option>
          <el-option label="商票" value="AC02"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" 票据包金额（元）" prop="tradeAmt">
        <el-input v-model.number="billInfoForm.tradeAmt"></el-input>
      </el-form-item>
      <el-form-item label="出票日期" prop="ticketDate">
        <el-date-picker
            v-model="billInfoForm.ticketDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions0"
            @change="changeDate">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="票据到期日" prop="dueDate">
        <el-date-picker
            v-model="billInfoForm.dueDate"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="pickerOptions1"
            @change="changeDate">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="自动提示" >
        <el-checkbox v-model="billInfoForm.tipsAcceptance"
                     true-label="1"
                     false-label="2">
          同意自动提示承兑
        </el-checkbox>
        <div class="tips">提示说明：出票成功后自动发起提示承兑申请，如承兑人与出票人相同，则自动承兑签收</div>
        <el-checkbox v-model="billInfoForm.tipsCollection"
                     true-label="1"
                     false-label="2">
          同意自动提示收票
        </el-checkbox>
        <div class="tips">提示说明：承兑签收后自动发起提示收票申请</div>
      </el-form-item>
      <el-form-item label=" 转让标记" prop="transferFlag">
        <el-select v-model="billInfoForm.transferFlag" placeholder="请选择转让标记">
          <el-option label="可转让" value="EM00"></el-option>
          <el-option label="不可转让" value="EM01"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="billInfoForm.remark"
                  type="textarea"
                  resize="none"></el-input>
      </el-form-item>
		</el-form>
		<el-button type="primary" @click="handleSubmit">保存并继续</el-button>
	</div>
</template>

<script>
  export default {
    name: "BillOutOne",
    props: ['id'],
    mounted(){
      // 获取出票人信息
      this.getDrawer()
      // 获取上一步返回时已填写的信息
      this.$nextTick(() => {
        this.getOneEdit()
      })
    },
	  data(){
      return {
        //保存后返回的和草稿页访问时带过来的 用于访问返回来的数据
        accountDrawer: [], // 出票人账号
	      accountAcceptor: [], //承兑人账号
	      accountPayee: [],    //收票人账号
	      pickerOptionsPublic: 1000 * 60 * 60 * 24,
        pickerOptions0: {
          disabledDate: time => {
            if (this.billInfoForm.dueDate){
              return (time.getTime() > new Date(this.billInfoForm.dueDate).getTime())
            }
          }
        },
        pickerOptions1: { // 票据到期日规则
          disabledDate: time => {
           if (this.billInfoForm.ticketDate){
             return (time.getTime() < new Date(this.billInfoForm.ticketDate).getTime())
           }else {
             return this.$message.error("请先选择出票日期")
           }
          }
        },
        billInfoForm: {
          billType: '', // 票据类别(AC01 银票 AC02 商票)
          tradeAmt: '', // 票据包金额（元）
          ticketDate: '', //出票日期
          dueDate: '', //票据到期日
          tipsAcceptance: 1, //自动提示承兑
          tipsCollection: 1, //自动提示收票
          transferFlag: '', //转让标记(EM00 可转让 EM01 不可转让)
          remark: '', // 备注
          drawer:'', //出票人
          acctNo: '', // 出票人账号
          drawerDepositBank: '', //出票人开户行
          drawerOpenBank: '', //出票人开户行行号
          acceptor: '', // 承兑人
          acceptorAcctNo: '', // 承兑人账号
          acceptorDepositBank: '', // 承兑人开户行
          acceptorOpenBank: '', // 承兑人开户行行号 - 商承
          acceptorOpenBankSilver: '', // 承兑人开户行行号 - 银承
          payee:'', // 收票人
          payeeAcctNo: '', //收票人账号
          payeeDepositBank: '', // 收票人开户行
          payeeOpenBank: '' //收票人开户行行号
        },
        billInfoRules: {
          billType: [
	          { required: true, message: '请输入票据类别', trigger: 'blur' }
          ],
          tradeAmt: [
	          { required: true, message: '请输入票据包金额（元）', trigger: 'blur' }
          ],
          ticketDate: [
	          { required: true, message: '请输入出票日期', trigger: 'blur' }
          ],
          dueDate: [
	          { required: true, message: '请输入票据到期日', trigger: 'blur' }
          ],
          transferFlag: [
            { required: true, message: '请选择转让标记', trigger: 'change' }
          ],
          drawer: [
	          { required: true, message: '请输入出票人', trigger: 'blur' }
          ],
          acctNo: [
            { required: true, message: '请选择出票人账号', trigger: 'change' }
          ],
          drawerDepositBank: [ // 开户行： 出票人、承兑人、收票人公用一个验证
            { required: true, message: '请输入开户行', trigger: 'blur' }
          ],
          drawerOpenBank: [// 开户行行号：出票人、承兑人、收票人公用一个验证
	          { required: true, message: '请输入开户行行号', trigger: 'blur' }
          ],
          acceptor: [
	          { required: true, message: '请输入承兑人', trigger: 'blur' }
          ],
          acceptorAcctNo: [
            { required: true, message: '请选择承兑人账号', trigger: 'change' }
          ],
          payee: [
            { required: true, message: '请输入收票人', trigger: 'blur' }
          ],
          payeeAcctNo: [
            { required: true, message: '请选择收票人账号', trigger: 'change' }
          ]
        }
      }
	  },
	  watch: {
      'billInfoForm.drawer':{
        handler(newValue){
          if (!newValue){
            this.getDrawer()
          }
        },
	      immediate: true
      }
	  },
	  methods: {
      // 承兑人与出票人一致
      handleDrawerSame(){
        if (this.billInfoForm.acctNo === null || this.billInfoForm.acctNo.length === 0){
          this.$message.error('请选择出票人账号')
        }else {
          this.billInfoForm.acceptor = this.billInfoForm.drawer   //承兑人
          this.billInfoForm.acceptorAcctNo = this.billInfoForm.acctNo // 承兑人账号
          this.billInfoForm.acceptorDepositBank = this.billInfoForm.drawerDepositBank // 承兑人开户行
          this.billInfoForm.acceptorOpenBank = this.billInfoForm.drawerOpenBank // 承兑人开户行 - 商承
          this.billInfoForm.acceptorOpenBankSilver = this.billInfoForm.drawerOpenBank// 承兑人开户行 - 银承
        }
      },
      // 出票日期和票据到期日规则
      changeDate(){
        let date1 = (new Date(this.billInfoForm.ticketDate).getTime())/1000/60/60/24
	      let date2 = new Date(this.billInfoForm.dueDate).getTime()/1000/60/60/24
	      let dateResult = date2 - date1
	      if(dateResult > 365){
	        this.$message.error('票据到期日不能大于出票日期365天')
          this.billInfoForm.dueDate = ''
	      }
      },
      
      // 获取上一步返回时已填写的信息
		  async getOneEdit(){
		    const {data: {ticketData: res}} = await this.$auth.post("/cpiaoju-ticket/ticket/backStep",this.$qs.stringify({id: this.id}));
			  this.billInfoForm = {...this.billInfoForm, ...res}
		  },
      // 获取出票人信息
      async getDrawer(){
        const {data: res} = await this.$auth.get('/cpiaoju-ticket/ticket/getDrawer');
	      if(res.code !== 200) return this.$message.error(res.msg);
	      this.billInfoForm.drawer = res.data.companyName; // 出票人
	      this.accountDrawer = res.data.list; // 出票人账号
      },
		  // 获取出票人开户行和开户行行号
		  async handleDrawerChange(value){
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/ticket/getBankSearch',this.$qs.stringify({bankNumber: value}));
        if(res.code !== 200) return this.$message.error(res.msg);
			  this.billInfoForm.drawerDepositBank = res.bankData.bankName;
			  this.billInfoForm.drawerOpenBank = res.bankData.clearingNumber
		  },
		  // 获取承兑人账号
      async handleAcceptorBlur(){
		    let params = {
          companyName: this.billInfoForm.acceptor,
          billType: this.billInfoForm.billType
		    }
		    /*if(!this.billInfoForm.billType){
		      this.billInfoForm.acceptor = ''
		      return this.$message.error('请先选择票据类别')
		    }*/
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/getAcceptorSearch",this.$qs.stringify(params));
        if(res.code !== 200) return this.$message.error(res.msg);
	      this.accountAcceptor = res.list
	      this.billInfoForm.acceptorOpenBankSilver = res.orgBankCode
      },
      // 获取承兑人开户行和开户行行号
      async handleAcceptorChange(value){
		    let params = {
          bankNumber: value,
          billType: this.billInfoForm.billType
		    }
        const {data: {bankData: {bankName, clearingNumber}}} = await this.$auth.post('/cpiaoju-ticket/ticket/getBankSearch',this.$qs.stringify(params));
        this.billInfoForm.acceptorDepositBank = bankName;
        this.billInfoForm.acceptorOpenBank = clearingNumber
      },
		  // 获取出票人账号
      async handlePayeeBlur(){
		    const params = {
          companyName: this.billInfoForm.payee,
          billType: this.billInfoForm.billType
		    }
        /*if(!this.billInfoForm.billType){
          this.billInfoForm.payee = ''
          return this.$message.error('请先选择票据类别')
        }*/
        const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/getSearch",this.$qs.stringify(params));
        if(res.code !== 200) return this.$message.error(res.msg)
        this.accountPayee = res.list
      },
		  // 获取收票人开户行和开户行行号
      async handlePayeeChange(value){
		    let params = {
          bankNumber: value,
          billType: this.billInfoForm.billType
		    }
        const {data: res} = await this.$auth.post('/cpiaoju-ticket/ticket/getBankSearch', this.$qs.stringify(params));
        if(res.code !== 200) return this.$message.error(res.msg)
        this.billInfoForm.payeeDepositBank = res.bankData.bankName;
        this.billInfoForm.payeeOpenBank = res.bankData.clearingNumber
      },
      //保存并继续
      handleSubmit(){
        console.log(111)
        this.$refs.billInfoRef.validate(async valid => {
          console.log(222)
          if (!valid) return this.$message.error('请填写必填项')
          console.log(333)
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/informaSave",this.$qs.stringify(this.billInfoForm))
	        if(res.code !== 200) return this.$message.error(res.msg);
	        this.billInfoForm.id = res.data;
	        // 告诉父组件可以下一步并且把用户id存储起来
          this.$emit('handleSave', {activeStep: 2, id:this.billInfoForm.id, tradeAmt: this.billInfoForm.tradeAmt})
        })
      }
	  }
  }
</script>

<style lang="less" scoped>
	.el-form{
		width: 50%;
		h3{
			margin-left: -150px;
		}
		.el-form-item{
			margin-bottom: 20px !important;
			.el-input, .el-select{
				width: 100%;
			}
		}
		.tips{
			line-height: 1.5;
			font-size: 12px;
			color: #C70009;
			padding-left: 25px;
			margin-top: -5px;
		}
		.el-textarea{
			/deep/ .el-textarea__inner{
				width: 100%;
				min-height: 100px !important;
			}
		}
	}
	.el-button{
		display: block;
		margin: 40px auto 0;
	}
</style>