<template>
	<div class="mt30">
		<div class="file-bill">
			<!--上传发票头部-->
			<div class="file-bill-top">
				<div class="file-bill-top-left">
					<span>发票信息</span>
					<span>请上传文件格式为png、jpeg、jpg格式的文件，单个文件大小在2M以内</span>
				</div>
				<div class="file-bill-top-right">
					<el-button v-if="invoiceList.length !== 0" @click="billDialogVisible = true">全部发票</el-button>
					<!-- 
						测试： http://117.122.218.200:11001
						生产： http://117.122.218.218:11001
					 -->
					<el-upload
									class="upload-demo"
									action="http://117.122.218.200:11001/cpiaoju-ticket/ticket/invoiceOcr"
									:show-file-list="false"
									name="files"
									:data="{id: this.id}"
									multiple
									accept=".png,.jpeg,.jpg"
									:before-upload="handleBeforeUpload"
									:on-success="handleSuccess">
						<el-button type="primary">批量上传发票</el-button>
					</el-upload>
				</div>
			</div>
			<!--上传发票展示区域-->
			<div class="file-bill-box">
				<div class="file-bill-left">
					<div class="file-big-img">
						<img :src="discernForm.invoiceUrl"
						     v-if="discernForm.invoiceUrl"
						     alt="">
						<div class="file-big-num"
						     v-show="isInvoiceList">
							<span>{{invoiceCurrentIndex + 1}}</span>/{{invoiceList.length}}
						</div>
					</div>
					<div class="file-total-money">
						价税合计：<span>{{total.toFixed(2)}}</span>元
					</div>
					<!--轮播图-->
					<div class="carousel-box">
						<Carousel class="carousel"
						          :list="invoiceList"
						          :id="this.id"
						          @handleCarouselClick="handleCarouselClick"
						          @carouselRemove="carouselRemove"/>
					</div>
				</div>
				<div class="file-bill-right">
					<el-form :model="discernForm"
					         :rules="rulesForm"
					         ref="formRef"
					         label-width="120px">
						<el-form-item label="校验结果：">
							<span class="verify-btn pass" v-if="discernForm.status =='1'">识别成功</span>
							<span class="verify-btn failure" v-if="discernForm.status =='2'">识别失败</span>
							<span class="verify-btn failure" v-if="discernForm.status =='3'">重复发票</span>
						</el-form-item>
						<el-form-item label="购方名称：" prop="payName">
							<el-input v-model="discernForm.payName"></el-input>
						</el-form-item>
						<el-form-item label="销方名称：" prop="sellerName">
							<el-input v-model="discernForm.sellerName"></el-input>
						</el-form-item>
						<el-form-item label="发票代码：" prop="invoiceCode">
							<el-input v-model="discernForm.invoiceCode"></el-input>
						</el-form-item>
						<el-form-item label="发票号码：" prop="invoiceNum">
							<el-input v-model="discernForm.invoiceNum"></el-input>
						</el-form-item>
						<el-form-item label="开票日期：" prop="invoiceDate">
							<el-date-picker
											v-model="discernForm.invoiceDate"
											type="date"
											format="yyyy-MM-dd"
											value-format="yyyy-MM-dd"
											placeholder="选择日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="价税合计：" prop="invoiceTotal">
							<el-input v-model="discernForm.invoiceTotal"
												style="width: 90%!important;margin-right: 5px;"></el-input>元
						</el-form-item>
						<el-form-item label="不含税金额：" prop="invoiceAmt">
							<el-input v-model="discernForm.invoiceAmt"
												style="width: 90%!important;margin-right: 5px;"></el-input>元
						</el-form-item>
						<el-form-item label="后6位校验码：" prop="checkCode" v-show="discernForm.invoiceType !== '专用发票'">
							<el-input v-model="discernForm.checkCode"></el-input>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<!--合同信息-->
		<div class="contract-info mt30">
			<h3>合同信息</h3>
			<el-form :model="contractForm"
			         :rules="rulesForm"
			         ref="formRef"
			         label-width="140px"
							 class="mt20">
				<el-form-item label="合同编号：" prop="contractNo">
					<el-input v-model="contractForm.contractNo"
                    placeholder="32位以内的数字或字母组成"
                    maxlength="32"></el-input>
				</el-form-item>
				<el-form-item label="合同日期：" prop="contractSignDate">
					<el-date-picker
									v-model="contractForm.contractSignDate"
									type="date"
									format="yyyy-MM-dd"
									value-format="yyyy-MM-dd"
									placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item type="number" label="合同金额（元）：" prop="contractAmt">
					<el-input v-model="contractForm.contractAmt"></el-input>
				</el-form-item>
				<el-form-item label="合同信息分类：" prop="tradeInfoType">
					<el-select placeholder="请选择" v-model="contractForm.tradeInfoType">
						<el-option label="合同" value="1"></el-option>
						<el-option label="订单" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="贸易背景方式：" prop="tradeMode">
					<el-select placeholder="请选择" v-model="contractForm.tradeMode">
						<el-option label="货物贸易" value="1"></el-option>
						<el-option label="服务贸易" value="2"></el-option>
						<el-option label="货服贸易" value="3"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="合同附件：" required>
					<el-upload
									action="http://117.122.218.200:11001/cpiaoju-ticket/ticket/uploadFile"
									accept=".jpg,jpeg,.bmp,.png,.pdf,.zip,.rar"
									class="upload-demo"
									list-type="picture-card"
									name="file"
									:show-file-list="false"
									:before-upload="handleContractBefore"
									:on-success="handleContractSuccess">
						<img class="upload-img aa"
						     v-if="contractForm.pdfImg"
						     :src="pdfImg"
						     alt="">
						<img class="upload-img bb"
						     v-else-if="contractForm.fileUrl"
						     :src="contractForm.fileUrl"
						     alt=""/>
						<i class="el-icon-plus" v-else></i>
						<div slot="tip" class="el-upload__tip">
							提示说明：<br/>
							1、可上传购销合同或订单证明文件<br/>
							2、仅允许上传类型为jpg、jpeg、bmp、png、pdf、zip、rar，文件名称不超过50位，并且不大于2MB的文件
						</div>
					</el-upload>
				</el-form-item>
			</el-form>
		</div>
		<div class="btn">
			<el-button @click="handleBack">上一步</el-button>
			<el-button type="primary" @click="handleSubmit">保存并继续</el-button>
		</div>
		<!--全部发票-->
		<el-dialog title="全部发票"
							 :visible.sync="billDialogVisible"
							 width="90%">
			<ul class="bill-all">
				<li v-for="(item, index) in invoiceList">
					<div class="bill-all-num">{{index + 1}}</div>
					<div class="bill-all-img">
						<img :src="item.invoiceUrl" alt="" v-if="item.invoiceUrl">
					</div>
					<div class="bill-all-input">
						<el-form :inline="true"
						         label-width="120px">
							<el-form-item label="校验结果：">
								<span class="verify-btn pass" v-if="item.status =='1'">识别成功</span>
								<span class="verify-btn failure" v-if="item.status =='2'">识别失败</span>
								<span class="verify-btn failure" v-if="item.status =='3'">重复发票</span>
							</el-form-item>
							<el-form-item label="购方名称：">
								<el-input v-model="item.payName"></el-input>
							</el-form-item>
							<el-form-item label="销方名称：">
								<el-input v-model="item.sellerName"></el-input>
							</el-form-item>
							<el-form-item label="发票代码：">
								<el-input v-model="item.invoiceCode"></el-input>
							</el-form-item>
							<el-form-item label="发票号码：">
								<el-input v-model="item.invoiceNum"></el-input>
							</el-form-item>
							<el-form-item label="开票日期：">
								<el-date-picker
												v-model="item.invoiceDate"
												type="date"
												format="yyyy-MM-dd"
												value-format="yyyy-MM-dd"
												placeholder="选择日期">
								</el-date-picker>
							</el-form-item>
							<el-form-item label="价税合计：">
								<el-input v-model="item.invoiceTotal"
								          style="width: 89%!important;margin-right: 5px;"></el-input>元
							</el-form-item>
							<el-form-item label="不含税金额：">
								<el-input v-model="item.invoiceAmt"
								          style="width: 89%!important;margin-right: 5px;"></el-input>元
							</el-form-item>
							<el-form-item label="后6位校验码：">
								<el-input v-model="item.checkCode" v-show="item.invoiceType !== '专用发票'"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</li>
			</ul>
		</el-dialog>
		<!--错误发票弹框-->
		<el-dialog
						title="提示"
						:visible.sync="billErrorDialogVisible"
						width="50%">
			<ul class="bill-error">
				<li v-for="(item,index) in errorList" :key="index">{{item}}</li>
			</ul>
			<span slot="footer" class="dialog-footer">
		    <el-button @click="billErrorDialogVisible = false">取 消</el-button>
		    <el-button type="primary" @click="billErrorDialogVisible = false">确 定</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	import Carousel from '@/views/Ticket/childrenCpn/Carousel'
  export default {
    name: "BillOutTwo",
	  components: {
      Carousel
	  },
	  props: ['id', 'tradeAmt'],
	  mounted(){
      // 获取上一步返回时已填写的信息
		  this.getTwoEdit()
	  },
    data(){
      // 验证合同编号的正则表达式
      const regContractNo = /^[A-Za-z0-9]+$/;
      var checkContractNo = (rule, value, callback) => {
        if (regContractNo.test(value)) {
          callback();
        }
        callback(new Error("合同编号需要32位数字与字母组合"));
      };
      return {
        billDialogVisible: false, //控制全部发票的显示与隐藏
        invoiceList: [],// 发票列表-上传后返回的识别数据
        invoiceCurrentIndex: 0, //发票列表当前数
        // 发票信息 - 识别出来的信息
        discernForm: {
          status: '',  //1识别成功2识别失败
          payName: '', //购买方名称
          sellerName: '', //销售方名称
					invoiceUrl: '',
          invoiceNum: '', //发票号码
          invoiceCode: '', //发票代码
          invoiceDate: '', //开票日期
          invoiceTotal: '', //价税合计
          invoiceAmt: '', //不含税金额
          checkCode: '', //后六位校验码 专票此项必须为空
        },
        rulesForm: {
          payName: [
	          { required: true, message: '请输入购方名称', trigger: 'blur' }
          ],
          sellerName: [
	          { required: true, message: '请输入销方名称', trigger: 'blur' }
          ],
          invoiceCode: [
	          { required: true, message: '请输入发票代码', trigger: 'blur' }
          ],
          invoiceNum: [
	          { required: true, message: '请输入发票号码', trigger: 'blur' }
          ],
          invoiceDate: [
	          { required: true, message: '请输入开票日期', trigger: 'blur' }
          ],
          invoiceTotal: [
	          { required: true, message: '请输入价税合计', trigger: 'blur' }
          ],
          invoiceAmt: [
	          { required: true, message: '请输入不含税金额', trigger: 'blur' }
          ],
          checkCode: [
	          { required: true, message: '请输入后6位校验码', trigger: 'blur' }
          ],
          contractNo: [
            { required: true, message: '请输入合同编号', trigger: 'blur' },
            { min: 0, max: 32, message: "只能输入32位字符", trigger: "blur", },
            { validator: checkContractNo, trigger: "blur" }
          ],
          contractSignDate: [
            { required: true, message: '请输入合同日期', trigger: 'blur' }
          ],
          contractAmt: [
            { required: true, message: '请输入合同金额', trigger: 'blur' }
          ],
          tradeInfoType: [
            { required: true, message: '请选择合同信息分类', trigger: 'change' }
          ],
          tradeMode: [
            { required: true, message: '请选择贸易背景方式', trigger: 'change' }
          ],
        },
        // 合同信息
        contractForm: {
          contractNo: '', //合同编号
          contractSignDate: '', //合同日期
          contractAmt: '', //合同金额
          tradeInfoType: '', //合同信息分类（1 合同 2 订单）
          tradeMode: '', //贸易背景方式（1-货物贸易 2-服务贸易 3-货服贸易）
          fileUrl: '', //合同上传后返回的路径
	        pdfImg: '', // 合同上传pdf返回的路径
        },
        errorList: [],// 验证失败发票的列表
        billErrorDialogVisible: false,
	      pdfImg: 'https://cpiaoju.oss-cn-beijing.aliyuncs.com/lxgw/gpxt/upload_image/pdf.png'
      }
	  },
	  computed: {
      // 价税合计
      total(){
        return this.invoiceList.reduce((preValue, item) => {
          return preValue + Number(item.invoiceTotal)
        },0)
      },
      isInvoiceList(){
        if (this.invoiceList.length == 0){
          return false
        }else {
          return true
        }
      }
	  },
	  methods: {
      // 监听上传发票之前的钩子
      handleBeforeUpload(file){
        let imgSize = Number(file.size / 1024 / 1024);
        if (imgSize > 2){
          this.$message.error('上传文件大小不能超过2M')
	        return false
        }
        return imgSize
      },
      // 监听上传发票成功回调
      handleSuccess(res, file, fileList){
        this.invoiceList.push(res.data)
      },
		  // 监听获取当前发票识别出的信息
      handleCarouselClick(value){
        this.discernForm = this.invoiceList[value] || {}
        //当前激活的个数
        this.invoiceCurrentIndex = value
      },
		  // 监听删除当前发票
      carouselRemove({invoiceList}){
				this.invoiceList = invoiceList
				this.invoiceCurrentIndex = 0
				// 判断当前发票列表的长度用来显示 发票列表当前数
				if (invoiceList.length == 0) {
					this.invoiceCurrentIndex = -1
          this.discernForm.invoiceUrl = ''
          this.discernForm.status = ''
          this.discernForm.payName = ''
          this.discernForm.sellerName = ''
          this.discernForm.invoiceCode = ''
          this.discernForm.invoiceNum = ''
          this.discernForm.invoiceDate = ''
          this.discernForm.invoiceTotal = ''
          this.discernForm.invoiceAmt = ''
          this.discernForm.checkCode = ''
				}
			},
      // 监听上传合同前回调
      handleContractBefore(file){
        let fileNameLen = file.name.substring(0, file.name.lastIndexOf('.'));
        let imgSize = Number(file.size / 1024 / 1024);
        if(fileNameLen.length > 50){
          this.$message.error('上传文件名称不能超过50位');
          return false;
        }
        if (imgSize > 2){
          this.$message.error('上传文件大小不能超过2M');
          return false;
        }
        return imgSize || fileNameLen
      },
		  // 监听上传合同附件成功
		  handleContractSuccess(res, file, fileList){
				this.contractForm.fileUrl = '';
				this.contractForm.pdfImg = '';
				if (res.pdfImg){
					this.contractForm.fileUrl = res.pdfImg;
					this.contractForm.pdfImg = res.pdfImg
				}else {
					this.contractForm.fileUrl = res.fileUrl
				}
		  },
		  // 获取上一步返回时已填写的信息
		  async getTwoEdit(){
        const {data: {ticketTrade: res}} = await this.$auth.post("/cpiaoju-ticket/ticket/submitBackStep", this.$qs.stringify({id: this.id}));
        if (res.pdfImg){
          this.contractForm.fileUrl = res.pdfImg
          this.contractForm.pdfImg = res.pdfImg
        }else {
          this.contractForm.fileUrl = res.fileUrl
        }
			  this.invoiceList = res.tradeInvoiceList;
			  this.contractForm.contractNo = res.contractNo;
        this.contractForm.contractSignDate = res.contractSignDate;
        this.contractForm.contractAmt = res.contractAmt;
        this.contractForm.tradeInfoType = res.tradeInfoType;
        this.contractForm.tradeMode = res.tradeMode;
        
		  },
		  // 上一步
      handleBack(){
        this.$emit('handleSave', {activeStep: 1, id:this.id})
      },
		  // 保存并继续
      handleSubmit(){
        this.$refs.formRef.validate(async valid => {
          if (!valid) return;
          if (Number(this.tradeAmt) > Number(this.contractForm.contractAmt)){
            return this.$message.error('合同金额要大于票据包金额')
          }else if (Number(this.tradeAmt > Number(this.total))){
            return this.$message.error('发票金额要大于票据包金额')
          }else if (!this.contractForm.fileUrl && !this.contractForm.pdfImg){
            return this.$message.error('合同附件必传')
          }else if(this.invoiceList.length === 0){
            return this.$message.error('发票必传')
          }
          let params = {
            registId: this.id,
            invoiceList: JSON.stringify(this.invoiceList),
            ...this.contractForm
          };
          const {data: res} = await this.$auth.post("/cpiaoju-ticket/ticket/tradeSave", this.$qs.stringify(params))
          if(res.code === 200) {
            this.$emit('handleSave', {activeStep: 3, id: res.id})
          }else {
            this.billErrorDialogVisible = true
            this.errorList = res.list
          }
        })
      }
	  }
  }
</script>

<style lang="less" scoped>
	.file-bill{
		.file-bill-top{
			display: flex;
			justify-content: space-between;
			.file-bill-top-left{
				line-height: 40px;
				span{
					display: inline-block;
				}
				span:first-child{
					font-size: 16px;
					color: #24262B;
					margin-right: 10px;
				}
				span:nth-of-type(2){
					font-size: 12px;
					color: #666;
					i{
						color: #D6000F;
					}
				}
			}
			.file-bill-top-right{
				display: flex;
				.verify-btn{
					display: inline-block;
					width: 110px;
					height: 40px;
					line-height: 40px;
					text-align: center;
				}
				.el-button{
					margin: 0 10px;
				}
			}
		}
		.file-bill-box{
			display: flex;
			width: 100%;
			margin-top: 25px;
			border: 1px solid #DEDEDE;
			box-sizing: border-box;
			.file-bill-left{
				width: 60%;
				padding-bottom: 15px;
				border-right: 1px solid #dedede;
				box-sizing: border-box;
				.carousel-box{
					height: 90px;
					padding: 0 15px;
					box-sizing: border-box;
				}
				.file-big-img{
					background: #F2F2F2;
					width: 100%;
					height: 362px;
					padding: 0 50px;
					box-sizing: border-box;
					position: relative;
					.file-big-num{
						position: absolute;
						top: 10px;
						right: 10px;
						span{
							font-size: 20px;
						}
					}
					img{
						width: 100%;
						height: 100%;
					}
				}
				.file-total-money{
					color: #666666;
					padding-left: 15px;
					margin-top: 15px;
					box-sizing: border-box;
					span{
						color: #D6000F;
						font-weight: 700;
					}
				}
			}
			.file-bill-right{
				width: 40%;
				padding-right: 20px;
				padding-top: 10px;
				padding-bottom: 10px;
				box-sizing: border-box;
				.verify-btn{
					display: inline-block;
					width: 100px;
					height: 40px;
					line-height: 40px;
					text-align: center;
					border-radius: 4px;
					box-sizing: border-box;
				}
				.pass{
					background: #F6FFED;
					color: #53C41C;
					border: 1px solid #53C41C;
				}
				.failure{
					background: #FFE6E5;
					color: #F00036;
					border: 1px solid #F00036;
				}
				.el-form-item{
					margin-bottom: 18px !important;
				}
				.el-form-item:first-of-type{
					margin-bottom: 10px !important;
				}
				.el-date-editor.el-input{
					width: 100%;
				}
			}
		}
	}
	.contract-info{
		.el-upload__tip{
			line-height: 1.5;
		}
		.el-select{
			width: 100%;
		}
		/deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner{
			width: 100%;
		}
	}
	.btn{
		text-align: center;
		margin: 50px auto 20px;
	}
	.upload-img{
		width: 146px;
		height: 146px;
	}
	.bill-all{
		width: 100%;
		height: 500px;
		overflow-y: auto;
		li{
			width: 100%;
			padding: 20px;
			margin-bottom: 20px;
			border: 1px solid #DEDEDE;
			box-sizing: border-box;
			display: flex;
			align-items: center;
		}
		.bill-all-num{
			width: 25px;
			font-size: 18px;
			color: #333;
		}
		.bill-all-img{
			background: #F2F2F2;
			width: 224px;
			height: 119px;
			padding: 7px;
			margin-right: 15px;
			box-sizing: border-box;
			img{
				width: 100%;
				height: 100%;
			}
		}
		.bill-all-input{
			flex: 1;
			flex: 1;
			.el-form-item{
				width: 31%;
			}
		}
	}
	.bill-error{
		li{
			line-height: 30px;
			color: #C70009;
		}
	}
</style>